<template>
  <div class="setting-item s-is-use-hint">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
    <div v-if="localSetting.value" class="setting-item__child-settings">
      <template v-for="item in localSetting.childs">
        <esmp-input
          class="setting-item__child-setting"
          :key="item.id"
          :label="item.name"
          :value="item.value"
          :options="{ type: 'textarea' }"
          @input="updateChild(item.id, $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SIsUseHint',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    updateChild(id, value) {
      this.localSetting.childs = this.localSetting.childs.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            value,
          };
        }
        return el;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/control-settings.scss';

.setting-item__child-settings {
  display: flex;
  flex-direction: column;
}
</style>
